import React, { ReactElement } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import * as l from '../styles/layout.css';
import SEO from './seo';



interface LayoutProps {
  pageTitle: string,
  children: ReactElement,
}




const Layout = ({ pageTitle, children }: LayoutProps) => {


  const data = useStaticQuery(graphql`
    query{
      site {
        siteMetadata {
          title
          description
          siteUrl
          titleTemplate
          }
        }   
    }  
  `)
  return (
    // REVISIT SEO AND DOUBLE CHECK THE META DESCRIPTION THING
    <>
      <SEO title={pageTitle} description={data.site.siteMetadata.description || data.allMdx.nodes.frontmatter.description} />
      <div className={l.container}>
        {/* <StaticImage src="../images/IMG_E4702.JPG" alt="maple leaves" className={l.imageBackground} /> */}

        <header className={l.header}>
          <nav className={l.nav}>
            <ul className={l.navUl}>
              <Link to="/" rel="home" title="Ben Foden Home">
                <li className={l.navLogo}>
                  <StaticImage src="../images/icon_white_only.png" loading="eager" alt="Ben Foden name logo" className={l.siteLogo} placeholder="dominantColor" />
                  <h1 className={l.siteTitle}>{data.site.siteMetadata.title}</h1>
                </li>
              </Link>
              <Link to="/about" activeClassName={l.navAActive} className={l.navA} >
                <li className={l.navLi}>
                  About
                </li>
              </Link>
              <Link to="/blog" activeClassName={l.navAActive} className={l.navA} >
                <li className={l.navLi}>
                  Blog
                </li>
              </Link>
              <Link to="/wiki" activeClassName={l.navAActive} className={l.navA} >
                <li className={l.navLi}>
                  Wiki
                </li>
              </Link>
              <Link to="/contact" activeClassName={l.navAActive} className={l.navA} >
                <li className={l.navLi}>
                  Contact
                </li>
              </Link>
            </ul>
          </nav>
        </header>
        <main className={l.main}>
          {children}
        </main>
      </div>
    </>
  )
};

export default Layout;

